import React, { useState } from "react";
import { Link } from "react-router-dom";
import companyLogo from "../assets/images/serityops_logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCaretDown } from "@awesome.me/kit-e790b06899/icons/classic/solid";
import "../styles/navbar.css";

/**
 * NavBar - The navigation bar component which contains the company logo and a dropdown menu.
 * @returns {React.Element} the rendered element
 */
const NavBar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  return (
    <nav className="navbar" role="navigation">
      <Link to="/" className="navbar-logo">
        <img src={companyLogo} alt="SerityOps Innovations Logo" />
      </Link>
      <button
        className={`navbar-toggler ${isDropdownOpen ? "active" : ""}`}
        onClick={toggleDropdown}
        type="button"
        aria-expanded={isDropdownOpen} // Indicates the state of the dropdown to assistive technologies
      >
        <FontAwesomeIcon icon={faCircleCaretDown} aria-hidden="true" />
      </button>

      {isDropdownOpen && (
        <div className="navbar-dropdown">
          <a href="/get-started" className="dropdown-item">
            Schedule Consultation
          </a>
        </div>
      )}
    </nav>
  );
};

export default NavBar;