import React from "react";
import { Link } from "react-router-dom";

import "../App.css";
import "../styles/footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-bottom">
        <div className="footer-copy">
          <p>
            © {new Date().getFullYear()} SerityOps Innovations, LLC. All rights
            reserved.
          </p>
        </div>
        <div className="footer-legal">
          <Link to="/privacy-policy"> Privacy Policy  </Link>
          |
          <Link to="/terms-conditions">  Terms & Conditions </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;