import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/servicepage.css";


const servicesPageDetails = {
  websiteDesign: {
    title: 'Website Design',
    description: 'Our approach to website design prioritizes a seamless user experience, marrying aesthetics, usability, and accessibility to distinguish your digital identity.',
    includedServices: [
      'Responsive Design ensuring optimal viewing across devices',
      'SEO Optimization for maximum online visibility',
      'Brand Integration that resonates with your corporate identity',
      'User-Centric Design for engaging user experiences',
      'Accessibility Compliance to reach a wider audience'
    ],
    icon: 'paintbrush'
  },
  webApplicationDevelopment: {
    title: 'Web Application Development',
    description: 'We craft dynamic web applications that drive business efficiency, integrating cutting-edge solutions with your unique processes for a secure and seamless operation.',
    includedServices: [
      'Bespoke Development tailored to your business needs',
      'Cloud Integration including AWS services for scalability and flexibility',
      'API Design and Integration for seamless service interoperability',
      'Robust Security and Compliance to protect your data and operations',
      'Performance Optimization for a swift user experience'
    ],
    icon: 'tablet-screen-button'
  },
  softwareApplicationDevelopment: {
    title: 'Software Application Development',
    description: 'From conceptualizing to maintaining, we cover the full Software Development Life Cycle (SDLC), delivering solutions that not only meet your needs but also embrace change and innovation.',
    includedServices: [
      'Tailored Software Solutions that evolve with your business',
      'Infrastructure as Code (IaC) for efficient and error-free cloud resource management',
      'Continuous Integration/Deployment for ongoing quality and productivity',
      'Thorough Quality Assurance to uphold the highest standards',
      'Comprehensive Support and Maintenance for lasting reliability'
    ],
    icon: 'laptop-code'
  },
};


const ServicesPageCard = ({ detail }) => (
  <div className="service-page-card">
    <div className="icon-and-services">
      <FontAwesomeIcon icon={['fas', detail.icon]} className="service-icon" size="6x" />
      <div>
        <h2>{detail.title}</h2>
        <p>{detail.description}</p>
        <ul className="services-list">
          {detail.includedServices.map((service, index) => (
            <li key={index}>{service}</li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

const ServicesPage = () => (
  <section id="service-page-details">
    <div className="container">
      {Object.values(servicesPageDetails).map((detail, index) => (
        <ServicesPageCard key={index} detail={detail} />
      ))}
    </div>
  </section>
);

export default ServicesPage;

